import { ReactNode } from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import Typography from '../Typography'
import { parseContent } from './RichText.utils'

interface RichTextProps extends RenderOptions {
  content: string
}

interface RenderOptions {
  typographyProps?: object
  nodeOptions?: Options['renderNode']
}

const getOptions = ({
  typographyProps,
  nodeOptions,
}: RenderOptions): Options => ({
  renderNode: {
    [BLOCKS.HEADING_1]: (_, children) => (
      <Typography variant="h1" {...typographyProps}>
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Typography variant="h2" {...typographyProps}>
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Typography variant="h3" {...typographyProps}>
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Typography variant="h4" {...typographyProps}>
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <Typography variant="h5" {...typographyProps}>
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <Typography variant="h6" {...typographyProps}>
        {children}
      </Typography>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Typography variant="body1" {...typographyProps}>
        {children}
      </Typography>
    ),
    ...nodeOptions,
  },
})

const RichText = ({ content, ...props }: RichTextProps) => {
  if (!content) return null

  const data = parseContent(content)
  return <>{documentToReactComponents(data, getOptions(props))}</>
}

export default RichText

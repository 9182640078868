import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { animateScroll } from 'react-scroll'
import { isBrowser, useAnalytics, useIsMobile } from '../../utils'
import { GalleryItemProps } from './GalleryItem'

const GALLERY_PAGE_SIZE = 4
const INITIAL_VISIBLE_ITEMS_COUNT = GALLERY_PAGE_SIZE * 2

export const useVisibleItems = (
  items: GalleryItemProps[],
  defaultVisibleItemsCount: number = INITIAL_VISIBLE_ITEMS_COUNT
) => {
  const analytics = useAnalytics()
  const isMobile = useIsMobile()
  const galleryContainerRef = useRef<HTMLDivElement | null>(null)
  const [visibleItemsCount, setVisibleItemsCount] = useState(
    defaultVisibleItemsCount
  )

  useEffect(() => {
    setVisibleItemsCount(defaultVisibleItemsCount)
  }, [defaultVisibleItemsCount])

  const visibleItems = useMemo(
    () => items.slice(0, visibleItemsCount),
    [items, visibleItemsCount]
  )

  const scrollDown = useCallback(() => {
    const items = galleryContainerRef.current?.children || []
    const lastItem = items[items.length - 1]
    const itemOffsetTop =
      lastItem.getBoundingClientRect().top + document.documentElement.scrollTop
    return animateScroll.scrollTo(itemOffsetTop, {
      smooth: 'easeInOut',
      duration: 500,
    })
  }, [isMobile])

  const loadMore = useCallback(() => {
    if (visibleItemsCount >= items.length) return

    setVisibleItemsCount((count) => count + GALLERY_PAGE_SIZE)
    analytics.showMoreProjects(
      visibleItemsCount - INITIAL_VISIBLE_ITEMS_COUNT / GALLERY_PAGE_SIZE + 1
    )
    if (isBrowser()) scrollDown()
  }, [visibleItemsCount, items.length, analytics])

  const isLoadMoreDisabled = visibleItems.length === items.length

  return {
    visibleItems,
    loadMore,
    isLoadMoreDisabled,
    galleryContainerRef,
  }
}

export const getPlaceholderItemsCount = (itemsCount: number) =>
  GALLERY_PAGE_SIZE - (itemsCount % GALLERY_PAGE_SIZE || GALLERY_PAGE_SIZE)

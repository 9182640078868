import {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import config from 'config'
import { isBrowser } from 'shared/utils'

export enum CookiesStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
  Unknown = 'unknown',
}

export interface CookiesContextValue {
  status: CookiesStatus | null
  areCookiesAccepted: boolean
  acceptCookies: () => void
  rejectCookies: () => void
}

export const defaultValue: CookiesContextValue = {
  status: null,
  areCookiesAccepted: false,
  acceptCookies: () => {},
  rejectCookies: () => {},
}

export const CookiesContext = createContext(defaultValue)

export const useCookies = () => useContext(CookiesContext)

export const useCookiesContext = (): CookiesContextValue => {
  const [status, setStatus] = useState<CookiesStatus | null>(null)

  useEffect(() => {
    if (!isBrowser()) return

    const initialStatus =
      (localStorage.getItem(config.cookiesStatusKey) as CookiesStatus) ||
      CookiesStatus.Unknown

    setStatus(initialStatus)
  }, [])

  useEffect(() => {
    if (!isBrowser() || !status) return
    localStorage.setItem(config.cookiesStatusKey, status)
  }, [status])

  const acceptCookies = useCallback(() => setStatus(CookiesStatus.Accepted), [])

  const rejectCookies = useCallback(() => setStatus(CookiesStatus.Rejected), [])

  const areCookiesAccepted = useMemo(
    () => status === CookiesStatus.Accepted,
    [status]
  )

  return {
    status,
    areCookiesAccepted,
    acceptCookies,
    rejectCookies,
  }
}

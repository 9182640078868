import styled from 'styled-components'

export const Container = styled.div`
  line-height: 1;
  & * {
    white-space: nowrap;
  }
`

export const IconLink = styled.a`
  display: inline-block;
  color: ${p => p.theme.palette.text.secondary};
  transition: ${p => p.theme.transition.create('color')};

  &:hover {
    cursor: pointer;
    color: ${p => p.theme.palette.text.muted};
  }
`

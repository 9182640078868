import { SVGAttributes } from 'react'

export interface QuoteSignProps extends SVGAttributes<SVGElement> {}

const QuoteSign = (props: QuoteSignProps) => (
  <svg
    width="64"
    height="56"
    viewBox="0 0 64 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M63.9857 15.2557C63.9857 15.2488 63.9864 15.2419 63.9864 15.235C63.9864 6.82083 57.1656 0 48.7514 0C40.3373 0 33.5166 6.8207 33.5166 15.235C33.5166 23.6492 40.3381 30.4699 48.7516 30.4699C50.4808 30.4699 52.1361 30.1685 53.6849 29.6374C50.2573 49.3005 34.9237 61.9813 49.1378 51.5448C64.8992 39.9718 64.0033 15.6998 63.9857 15.2557Z"
      fill="#F7F7F7"
    />
    <path
      d="M15.235 30.4698C16.9642 30.4698 18.6195 30.1683 20.1691 29.6372C16.7406 49.3004 1.407 61.9811 15.6212 51.5446C31.3826 39.9718 30.4868 15.6998 30.469 15.2557C30.469 15.2488 30.4698 15.2419 30.4698 15.235C30.4698 6.82083 23.6491 0 15.235 0C6.8207 0 0 6.8207 0 15.235C0 23.6492 6.82146 30.4698 15.235 30.4698Z"
      fill="#F7F7F7"
    />
  </svg>
)

export default QuoteSign

import { Breakpoint, breakpoints } from 'config'
import useBreakpoints from 'use-breakpoint'

export const isBrowser = () => typeof window !== 'undefined'

export const isMobile = (breakpoint: Breakpoint) =>
  ['xs', 'sm'].includes(breakpoint)

export const useBreakpoint = (breakpoint: Breakpoint = 'md') =>
  useBreakpoints(breakpoints, breakpoint)

export const useIsMobile = (defaultBreakpoint?: Breakpoint) => {
  const { breakpoint } = useBreakpoint(defaultBreakpoint)
  return isMobile(breakpoint)
}

export const toPairs = <T>(arr: T[]) => {
  const newArr = []
  while (arr.length > 0) {
    newArr.push(arr.splice(0, 3))
  }
  return newArr
}

export const getMenuItemUrl = ({
  section,
  url,
}: {
  section?: { id: string }
  url?: string
}) => (section ? `/#${section.id}` : url) || '/'

export const toKebabCase = (str: string) =>
  str.trim().replace(/[\s_]/g, '-').toLowerCase()

import { logEvent } from 'firebase/analytics'
import { useCookies, useFirebase } from 'shared/components'

export const useAnalytics = () => {
  const { areCookiesAccepted } = useCookies()
  const { analytics } = useFirebase()

  const fbEvent = (areCookiesAccepted && window.fbq) || (() => {})
  const firebaseEvent = (areCookiesAccepted && logEvent) || (() => {})

  const selectVariant = (name: string) => {
    fbEvent('track', 'SelectVariant', { content_name: name })
    firebaseEvent(analytics, 'select_variant', { name })
  }

  const submitContactForm = (variant: string) => {
    fbEvent('track', 'Contact', { content_name: variant })
    firebaseEvent(analytics, 'submit_contact_form', { variant })
  }
  const toggleTestimonialComment = (index: number) => {
    fbEvent('track', 'ToggleTestimonialComment', { index })
    firebaseEvent(analytics, 'toggle_testimonial_comment', { index })
  }

  const focusTestimonial = (index: number) => {
    fbEvent('track', 'FocusTestimonial', { index })
    firebaseEvent(analytics, 'focus_testimonial', { index })
  }

  const openProject = (url: string) => {
    fbEvent('track', 'OpenProject', { url })
    firebaseEvent(analytics, 'open_project', { url })
  }

  const showMoreProjects = (page: number) => {
    fbEvent('track', 'ShowMoreProjects', { page })
    firebaseEvent(analytics, 'show_more_projects', { page })
  }

  const openSocialMedia = (type: string) => {
    fbEvent('track', 'OpenSocialMedia', { type })
    firebaseEvent(analytics, 'open_social_media', { type })
  }

  return {
    selectVariant,
    submitContactForm,
    toggleTestimonialComment,
    focusTestimonial,
    showMoreProjects,
    openProject,
    openSocialMedia,
  }
}

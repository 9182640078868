import { Link, GatsbyLinkProps } from 'gatsby'
import { forwardRef, useCallback } from 'react'
import { animateScroll } from 'react-scroll'

const SCROLL_OFFSET = 150

const getElementId = (url: string) => url.replace(/^\/?#/, '')

const ScrollLink = forwardRef<any, GatsbyLinkProps<any>>(
  ({ onClick, to, ...props }, ref) => {
    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (onClick) onClick(e)

        const element = document.getElementById(getElementId(to))
        if (!element) return

        animateScroll.scrollTo(element.offsetTop - SCROLL_OFFSET, {
          smooth: 'easeInOutQuint',
        })
      },
      []
    )
    return <Link {...props} onClick={handleClick} ref={ref} to={to} />
  }
)

export default ScrollLink

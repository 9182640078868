import * as FeatherIcon from 'react-feather'
import { IconName } from '../../../types'
import { useAnalytics } from '../../../utils'
import ScrollLink from '../../ScrollLink'
import Typography from '../../Typography'
import { Container, IconLink } from './FooterItem.style'

export interface FooterItemProps {
  url: string
  label?: string
  icon?: IconName
}

const FooterItem = ({ url, icon, label }: FooterItemProps) => {
  const analytics = useAnalytics()
  if (!icon)
    return (
      <Container>
        <Typography variant="button" color="light" align="center">
          <ScrollLink to={url}>{label}</ScrollLink>
        </Typography>
      </Container>
    )

  const Icon = FeatherIcon[icon] || FeatherIcon.HelpCircle
  return (
    <Container>
      <IconLink
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        title={label}
        onClick={() => analytics.openSocialMedia(label || '')}
      >
        <Icon />
      </IconLink>
    </Container>
  )
}

export default FooterItem

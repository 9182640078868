import { PropsWithChildren } from 'react'
import {
  FirebaseContext,
  useFirebaseContext,
} from './FirebaseContextProvider.utils'
import type { FirebaseContextValue } from './FirebaseContextProvider.utils'

interface FirebaseContextProviderProps extends PropsWithChildren {
  value?: FirebaseContextValue
}

const FirebaseContextProvider = (props: FirebaseContextProviderProps) => {
  const value = useFirebaseContext()
  return <FirebaseContext.Provider value={value} {...props} />
}

export default FirebaseContextProvider

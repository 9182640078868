import styled, { css, CSSProperties } from 'styled-components'
import { Breakpoint } from 'config'

interface HiddenProps {
  up?: Breakpoint
  down?: Breakpoint
  display?: CSSProperties['display']
}

const Hidden = styled.div<HiddenProps>(
  ({ theme, up, down, display = 'block' }) => css`
    display: ${display};

    ${up &&
    css`
      ${theme.breakpoints.up(up)} {
        display: none;
      }
    `};

    ${down &&
    css`
      ${theme.breakpoints.down(down)} {
        display: none;
      }
    `};
  `
)

export default Hidden

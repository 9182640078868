import { HTMLAttributes } from 'react'
import { Loader as BaseLoader } from 'react-feather'
import styled, { css, keyframes } from 'styled-components'

const rotate = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`

export const Loader = styled(BaseLoader)`
  animation: ${rotate} 2s infinite linear;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
`

export type ButtonVariant = 'outlined' | 'filled'
export type ButtonColor = 'primary' | 'secondary'

interface StyledButtonProps extends HTMLAttributes<HTMLButtonElement> {
  $variant: ButtonVariant
  $color: ButtonColor
  $roundIcon?: boolean
  $loading?: boolean
  disabled?: boolean
}

const styles = {
  filled: css<StyledButtonProps>`
    background-color: ${p => p.theme.palette[p.$color].main};
    color: ${p => p.theme.palette[p.$color].contrastText};
  `,
  outlined: css<StyledButtonProps>`
    background-color: transparent;
    color: ${p => p.theme.palette[p.$color].main};
  `,
}

const disabledStyle = {
  filled: css<StyledButtonProps>`
    border-color: ${p => p.theme.palette.text.light};
    background-color: ${p => p.theme.palette.text.light};
    color: ${p => p.theme.palette.text.secondary};
  `,
  outlined: css<StyledButtonProps>`
    border-color: ${p => p.theme.palette.text.light};
    background-color: transparent;
    color: ${p => p.theme.palette.text.light};
  `,
}

export const StyledButton = styled.button<StyledButtonProps>`
  ${p => styles[p.$variant]};
  border: 1px solid ${p => p.theme.palette[p.$color].main};
  position: relative;
  outline: none;
  box-sizing: border-box;
  border-radius: 20px;
  height: 32px;
  padding: ${p => (p.$roundIcon ? p.theme.spacing(0) : p.theme.spacing(1, 3))};
  ${p =>
    p.$roundIcon &&
    css`
      width: 32px;
      & > * {
        line-height: 0;
      }
    `};
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: ${p =>
    p.theme.transition.create(['color, background-color'], 100)};

  &:hover {
    ${p =>
      p.disabled
        ? css`
            cursor: not-allowed;
          `
        : css`
            ${styles.filled};
            cursor: pointer;
          `}
  }

  ${p => p.disabled && disabledStyle[p.$variant]};
  ${p =>
    p.$loading &&
    css`
      & > *:not(${Loader}) {
        visibility: hidden;
      }
    `};
`

import { IGatsbyImageData } from 'gatsby-plugin-image'
import { HTMLAttributes } from 'react'
import { Heart } from 'react-feather'
import Typography from '../../Typography'
import { Image, ItemContainer, Likes, Overlay } from './GalleryItem.style'

export interface GalleryItemProps
  extends HTMLAttributes<HTMLDivElement | HTMLAnchorElement> {
  image: IGatsbyImageData | null
  title: string
  description: string
  url: string
  likes: number
}

const GalleryItem = ({
  image,
  url,
  title,
  description,
  likes,
  ...props
}: GalleryItemProps) => (
  <ItemContainer to={url} {...props}>
    <Overlay>
      {title && (
        <Typography color="secondary" align="center">
          <b>{title}</b>
        </Typography>
      )}
      <Typography color="secondary" align="center">
        {description}
      </Typography>
      {likes && (
        <Likes>
          <Heart />
          {likes}
        </Likes>
      )}
    </Overlay>
    {image && <Image loading="eager" image={image} alt={title} />}
  </ItemContainer>
)

export default GalleryItem

import { Theme } from 'config'
import { ComponentType } from 'react'
import styled, { css, CSSProperties } from 'styled-components'

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'

export type TypographyColor = keyof Theme['palette']['text'] | 'inherit'

export interface TypographyComponentProps {
  color?: TypographyColor
  align?: CSSProperties['textAlign']
  paragraph?: boolean
  gutterBottom?: boolean
}

const commonStyles = css<TypographyComponentProps>`
  white-space: pre-wrap;
  text-align: ${p => p.align || ''};
  color: ${p =>
    p.color === 'inherit'
      ? p.color
      : p.theme.palette.text[p.color || 'primary']};
  ${p =>
    p.paragraph || p.gutterBottom
      ? css`
          margin-bottom: ${p => p.theme.typography.rhythm(0.675)};
        `
      : css`
          margin-bottom: 0;
        `}
`

export const typographyComponents: Record<TypographyVariant, ComponentType> = {
  h1: styled.h1`
    ${commonStyles};
    margin-bottom: ${p => p.theme.typography.rhythm(0.675)};
    ${p => p.theme.breakpoints.down('md')} {
      font-size: 2rem;
    }
  `,
  h2: styled.h2`
    ${commonStyles};
    font-size: 2.25rem;
    margin-bottom: ${p => p.theme.typography.rhythm(0.5)};
    ${p => p.theme.breakpoints.down('md')} {
      font-size: 1.5rem;
    }
  `,
  h3: styled.h3`
    ${commonStyles};
    font-size: 1.25rem;
    font-weight: 700;
  `,
  h4: styled.h4`
    ${commonStyles};
  `,
  h5: styled.h5`
    ${commonStyles};
  `,
  h6: styled.h6`
    ${commonStyles};
  `,
  body1: styled.p`
    ${commonStyles};
    letter-spacing: 0.05rem;
  `,
  body2: styled.p`
    ${commonStyles};
    font-size: 0.875rem;
  `,
  button: styled.span`
    ${commonStyles};
    line-height: 1;
    font-size: 0.75rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0;
  `,
  caption: styled.small`
    ${commonStyles};
    font-size: 0.75rem;
  `,
}

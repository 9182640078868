const palette = {
  primary: {
    main: '#000000',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#ffffff',
    contrastText: '#000000',
  },
  text: {
    primary: '#000000',
    secondary: '#ffffff',
    muted: '#777777',
    light: '#bdbdbd',
    dark: '#303030',
  },
  background: {
    primary: '#ffffff',
    light: '#f7f7f7',
    muted: '#f0f0f0',
    dark: '#212121',
  },
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  error: {
    main: '#e05534',
  },
}

const fade = (hexColor: string, alpha: number) => {
  const [r = 0, g = 0, b = 0] = (hexColor.match(/\w\w/g) || []).map(x =>
    parseInt(x, 16)
  )
  return `rgba(${r},${g},${b},${alpha})`
}

export default {
  ...palette,
  fade,
}

import styled from 'styled-components'

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${p => p.theme.palette.background.primary};
  overflow: hidden;
`

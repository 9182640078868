import { ComponentType, HTMLAttributes } from 'react'
import {
  typographyComponents,
  TypographyComponentProps,
  TypographyVariant,
} from './Typography.style'

export interface TypographyProps
  extends TypographyComponentProps,
    Omit<HTMLAttributes<HTMLElement>, 'color'> {
  variant?: TypographyVariant
}

const Typography = ({ variant = 'body1', ...props }: TypographyProps) => {
  const Component: ComponentType<TypographyComponentProps> =
    typographyComponents[variant]

  return Component ? <Component {...props} /> : null
}

export default Typography

import { fadeInAnimation } from 'config'
import { useMemo } from 'react'
import { MoreHorizontal } from 'react-feather'
import { useAnalytics } from '../../utils'
import Button from '../Button'
import { Container, GalleryContainer } from './Gallery.style'
import { getPlaceholderItemsCount, useVisibleItems } from './Gallery.utils'
import GalleryItem, {
  GalleryItemPlaceholder,
  GalleryItemProps,
} from './GalleryItem'

export interface GalleryProps {
  items: GalleryItemProps[]
  visibleItemsCount?: number
  hideLoadMore?: boolean
}

const Gallery = ({
  items,
  visibleItemsCount,
  hideLoadMore = false,
  ...props
}: GalleryProps) => {
  const analytics = useAnalytics()
  const { visibleItems, loadMore, isLoadMoreDisabled, galleryContainerRef } =
    useVisibleItems(items, visibleItemsCount)

  const placeholderElementsCount = useMemo(
    () => getPlaceholderItemsCount(visibleItems.length),
    [visibleItems.length]
  )

  return (
    <Container {...props}>
      <GalleryContainer ref={galleryContainerRef}>
        {visibleItems.map((props, i) => (
          <GalleryItem
            key={`gallery-item-${i}`}
            onClick={() => analytics.openProject(props.url)}
            {...props}
            {...fadeInAnimation}
            className="sal-animate"
          />
        ))}
        {Array.from({ length: placeholderElementsCount }, (_, i) => (
          <GalleryItemPlaceholder key={`placeholder-${i}`} />
        ))}
      </GalleryContainer>
      {!hideLoadMore && (
        <Button
          onClick={loadMore}
          disabled={isLoadMoreDisabled}
          aria-label="Wczytaj więcej"
        >
          <MoreHorizontal />
        </Button>
      )}
    </Container>
  )
}

export default Gallery

import { HTMLAttributes } from 'react'
import Typography from '../Typography'
import {
  ButtonColor,
  ButtonVariant,
  StyledButton,
  Loader,
} from './Button.style'

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  color?: ButtonColor
  variant?: ButtonVariant
  roundIcon?: boolean
  disabled?: boolean
  loading?: boolean
}

const Button = ({
  children,
  roundIcon,
  disabled = false,
  loading = false,
  color = 'primary',
  variant = 'outlined',
  ...props
}: ButtonProps) => (
  <StyledButton
    $color={color}
    $variant={variant}
    $roundIcon={roundIcon}
    $loading={loading}
    disabled={disabled || loading}
    {...props}
  >
    {loading && <Loader />}
    <Typography variant="button" align="center" color="inherit">
      {children}
    </Typography>
  </StyledButton>
)

export default Button

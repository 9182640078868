import styled from 'styled-components'
import { galleryItemSize } from './GalleryItem'
import { isBrowser } from 'shared/utils'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > *:not(:first-child) {
    margin-top: ${p => p.theme.spacing(2)};
  }
`

export const GalleryContainer = styled.div`
  line-height: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: ${p => p.theme.breakpoints.md}px;
  width: 100%;
`

import { ReactNode, useEffect, useRef, useState } from 'react'
import { animateScroll } from 'react-scroll'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import Typography from '../Typography'
import Hidden from '../Hidden'
import {
  Container,
  MobileNavigation,
  Navigation,
  PaddingFix,
  ToggleButton,
} from './TopBar.style'
import ScrollLink from '../ScrollLink'
import { paths } from 'config'
import { Link } from 'gatsby'

export interface TopBarItem {
  label: string
  url: string
}

export interface TopBarProps {
  logo: ReactNode
  items: TopBarItem[]
}

const TopBar = ({ logo, items }: TopBarProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const mobileNavigationRef = useRef<HTMLDivElement | null>(null)

  useScrollPosition(({ currPos }) => {
    setIsCollapsed(currPos.y < 0)
  })

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  const open = () => {
    setIsOpen(true)
    if (mobileNavigationRef.current) {
      disableBodyScroll(mobileNavigationRef.current)
    }
  }

  const close = () => {
    setIsOpen(false)
    if (mobileNavigationRef.current) {
      enableBodyScroll(mobileNavigationRef.current)
    }
  }

  return (
    <>
      <Container $collapsed={isCollapsed}>
        <Link
          to={paths.index}
          onClick={() =>
            animateScroll.scrollToTop({ smooth: 'easeInOutQuint' })
          }
        >
          {logo}
        </Link>
        <Hidden down="md">
          <Navigation>
            {items.map(({ url, label }) => (
              <Typography
                variant="button"
                color="primary"
                key={`topbar-link-${url}`}
              >
                <ScrollLink to={url}>{label}</ScrollLink>
              </Typography>
            ))}
          </Navigation>
        </Hidden>
      </Container>
      <Hidden up="md">
        <MobileNavigation
          $open={isOpen}
          $collapsed={isCollapsed}
          ref={mobileNavigationRef}
        >
          {items.map(({ url, label }) => (
            <ScrollLink key={`mobilenav-link-${url}`} to={url} onClick={close}>
              <Typography variant="body1" color="secondary" align="center">
                {label}
              </Typography>
            </ScrollLink>
          ))}
        </MobileNavigation>
        <ToggleButton
          $collapsed={isCollapsed}
          open={isOpen}
          color={isOpen ? 'secondary' : 'primary'}
          onClick={isOpen ? close : open}
        />
      </Hidden>
      <PaddingFix $collapsed={isCollapsed} />
    </>
  )
}

export default TopBar

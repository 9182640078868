import { PropsWithChildren } from 'react'
import {
  CookiesContext,
  useCookiesContext,
} from './CookiesContextProvider.utils'
import type { CookiesContextValue } from './CookiesContextProvider.utils'

interface CookiesContextProviderProps extends PropsWithChildren {
  value?: CookiesContextValue
}

const CookiesContextProvider = (props: CookiesContextProviderProps) => {
  const value = useCookiesContext()
  return <CookiesContext.Provider value={value} {...props} />
}

export default CookiesContextProvider

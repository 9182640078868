import styled from 'styled-components'

export const BUTTON_WIDTH = 30
export const BUTTON_HEIGHT = 20
export const TRANSITION_DURATION = 400

export type MenuButtonColor = 'primary' | 'secondary'

export const Button = styled.div`
  position: relative;
  width: ${BUTTON_WIDTH}px;
  height: ${BUTTON_HEIGHT}px;
  background: transparent;
  outline: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
`

interface LineProps {
  $color: MenuButtonColor
}

export const Line = styled.span<LineProps>`
  position: absolute;
  display: block;
  margin-top: -1px;
  height: 2px;
  width: 100%;
  background: ${p => p.theme.palette[p.$color].main};
  transition-timing-function: ease;
  transition-duration: ${TRANSITION_DURATION}ms;
  transform-origin: center;
  border-radius: 1px;
`

interface MiddleLineProps {
  $open?: boolean
}

export const MiddleLine = styled(Line)<MiddleLineProps>`
  transition-timing-function: ease-out;
  transition-duration: ${TRANSITION_DURATION / 4}ms;
  opacity: ${p => (p.$open ? 0 : 1)};
  top: ${BUTTON_HEIGHT / 2}px;
`

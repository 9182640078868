import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react'
import CookieBanner from '../CookieBanner'
import SEO from '../SEO'
import StyleProvider from './StyleProvider'
import { Main } from './Layout.style'
import { useBodyBackgroundColor } from './Layout.utils'

export interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
  seo?: ReactNode | null
  topBar?: ReactNode | null
  footer?: ReactNode | null
}

const Layout = ({
  children,
  topBar,
  footer,
  seo = <SEO />,
  ...props
}: PropsWithChildren<LayoutProps>) => {
  useBodyBackgroundColor()
  return (
    <>
      <StyleProvider>
        {seo}
        {topBar}
        <Main {...props}>{children}</Main>
        {footer}
        <CookieBanner />
      </StyleProvider>
    </>
  )
}

export default Layout

import { fadeInAnimation } from 'config'
import PricingCard, { PricingCardProps } from './PricingCard'
import { Container } from './PricingTable.style'

export interface PricingTableProps {
  items: PricingCardProps[]
}

const PricingTable = ({ items, ...props }: PricingTableProps) => (
  <Container {...props}>
    {items.map((item, i) => (
      <PricingCard key={`pricing-${i}`} {...fadeInAnimation} {...item} />
    ))}
  </Container>
)

export default PricingTable

import { HTMLAttributes } from 'react'
import {
  Adornment,
  Container,
  ErrorLabel,
  Label,
  StyledSelect,
} from './Select.style'

const DEFAULT_VALUE = ''

export interface SelectOption {
  label: string
  value: string
}

export interface SelectProps extends HTMLAttributes<HTMLSelectElement> {
  options?: SelectOption[]
  type?: string
  name?: string
  value?: string
  label?: string
  error?: string
  disabled?: boolean
  placeholder?: string
}

const Select = ({
  name,
  label,
  error,
  placeholder,
  value = DEFAULT_VALUE,
  disabled = false,
  options = [],
  ...props
}: SelectProps) => (
  <Container $error={!!error} $disabled={disabled}>
    <Label htmlFor={name}>{label}</Label>
    <StyledSelect
      id={name}
      value={value}
      disabled={disabled}
      name={name}
      $empty={value === DEFAULT_VALUE}
      {...props}
    >
      {placeholder && (
        <option value={DEFAULT_VALUE} disabled>
          {placeholder}
        </option>
      )}
      {options.map(({ value, label }) => (
        <option key={`option-${value}`} value={value}>
          {label}
        </option>
      ))}
    </StyledSelect>
    <Adornment />
    {error && <ErrorLabel>{error}</ErrorLabel>}
  </Container>
)

export default Select

import { useState } from 'react'
import { useAnalytics } from 'shared/utils'
import Typography from '../../Typography'
import {
  Container,
  StyledQuoteSign,
  MoreButton,
  Author,
} from './TestimonialsItem.style'

const CHARACTER_LIMIT = 465

export interface TestimonialsItemProps {
  comment: string
  author: string
  onClick?: () => void
}

const TestimonialItem = ({
  comment,
  author,
  onClick = () => {},
}: TestimonialsItemProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const isOverflowing = comment.length > CHARACTER_LIMIT

  const toggle = () => {
    onClick()
    setIsOpen(open => !open)
  }

  return (
    <Container>
      <StyledQuoteSign />
      <Typography variant="body2" align="left" gutterBottom>
        {isOpen || !isOverflowing
          ? comment
          : `${comment.slice(0, CHARACTER_LIMIT).trim()}...`}
        {isOverflowing && (
          <>
            &nbsp;
            <MoreButton onClick={toggle}>
              {isOpen ? 'Mniej' : 'Więcej'}
            </MoreButton>
          </>
        )}
      </Typography>
      <Author>{author}</Author>
    </Container>
  )
}

export default TestimonialItem

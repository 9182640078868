const DURATION = 250
const TIMING_FUNCTION = 'ease-in-out'

const create = (
  string: string | string[],
  durationMs: number = DURATION,
  timingFunction: string = TIMING_FUNCTION
) => {
  const getTransition = (prop: string) =>
    `${prop} ${durationMs}ms ${timingFunction}`

  return Array.isArray(string)
    ? string.map(getTransition).join()
    : getTransition(string)
}

export default {
  create,
  duration: DURATION,
  timingFunction: TIMING_FUNCTION,
}

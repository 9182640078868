import { ComponentType, HTMLProps } from 'react'
import { TextareaAutosizeProps } from 'react-textarea-autosize'
import {
  Container,
  ErrorLabel,
  Input,
  Label,
  Length,
  Textarea,
} from './TextField.style'

export interface TextFieldProps
  extends Omit<HTMLProps<HTMLInputElement>, 'ref'>,
    Pick<TextareaAutosizeProps, 'minRows' | 'maxRows'> {
  type?: string
  name?: string
  value?: string
  label?: string
  error?: string
  disabled?: boolean
  multiline?: boolean
}

const TextField = ({
  name,
  label,
  error,
  maxLength,
  minRows = 0,
  value = '',
  disabled = false,
  multiline = false,
  ...props
}: TextFieldProps) => {
  const InputComponent: ComponentType<any> = multiline ? Textarea : Input
  return (
    <Container $error={!!error} $disabled={disabled}>
      <Label htmlFor={name}>{label}</Label>
      <InputComponent
        id={name}
        name={name}
        disabled={disabled}
        value={value}
        maxLength={maxLength}
        {...props}
      />
      {error && <ErrorLabel>{error}</ErrorLabel>}
      {maxLength && (
        <Length>
          {value.length} / {maxLength}
        </Length>
      )}
    </Container>
  )
}

export default TextField

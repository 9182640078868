import { SVGAttributes } from 'react'

export interface LogoVerticalProps extends SVGAttributes<SVGElement> {}

const LogoVertical = (props: LogoVerticalProps) => (
  <svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    version="1.1"
    viewBox="0 0 324 475"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-3.3691 -67.748)">
      <g transform="matrix(4.1667 0 0 4.1667 -19.031 29.662)">
        <g transform="matrix(20 0 0 20 19.593 112)">
          <path
            d="m0.713-0.71c0.013 0 0.023 4e-3 0.03 0.011 6e-3 8e-3 0.01 0.018 0.01 0.031v0.633c0 0.012-3e-3 0.022-0.01 0.029s-0.016 0.011-0.028 0.011-0.021-4e-3 -0.027-0.011-9e-3 -0.017-9e-3 -0.029v-0.511l-0.217 0.416c-9e-3 0.019-0.023 0.029-0.041 0.029-0.017 0-0.031-0.01-0.041-0.029l-0.218-0.411v0.506c0 0.012-3e-3 0.022-9e-3 0.029-7e-3 7e-3 -0.016 0.011-0.028 0.011s-0.021-4e-3 -0.028-0.011-0.01-0.017-0.01-0.029v-0.633c0-0.013 4e-3 -0.023 0.011-0.031 7e-3 -7e-3 0.017-0.011 0.03-0.011 0.017 0 0.03 0.01 0.04 0.029l0.254 0.487 0.251-0.487c5e-3 -0.011 0.011-0.018 0.018-0.023 6e-3 -4e-3 0.013-6e-3 0.022-6e-3z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(20 0 0 20 36.413 112)">
          <path
            d="m0.694-0.047c3e-3 5e-3 4e-3 0.011 4e-3 0.017 0 0.01-4e-3 0.018-0.011 0.025-8e-3 7e-3 -0.017 0.01-0.028 0.01-0.017 0-0.028-8e-3 -0.035-0.024l-0.071-0.162h-0.377l-0.072 0.162c-7e-3 0.016-0.018 0.024-0.035 0.024-0.011 0-0.02-3e-3 -0.028-0.011-8e-3 -6e-3 -0.012-0.015-0.012-0.025 0-5e-3 1e-3 -0.011 4e-3 -0.016l0.286-0.636c4e-3 -9e-3 0.01-0.016 0.018-0.021s0.017-7e-3 0.026-7e-3c0.01 0 0.019 3e-3 0.027 7e-3 8e-3 5e-3 0.014 0.012 0.018 0.021l0.286 0.636zm-0.488-0.202h0.317l-0.159-0.359-0.158 0.359z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(20 0 0 20 49.693 112)">
          <path
            d="m0.604-0.689c3e-3 -7e-3 8e-3 -0.013 0.015-0.017 6e-3 -4e-3 0.013-6e-3 0.02-6e-3 0.011 0 0.02 4e-3 0.029 0.01 8e-3 7e-3 0.012 0.016 0.012 0.026 0 5e-3 -1e-3 0.011-4e-3 0.016l-0.287 0.638c-4e-3 9e-3 -0.01 0.015-0.018 0.02s-0.017 7e-3 -0.026 7e-3 -0.018-2e-3 -0.026-7e-3 -0.014-0.011-0.018-0.02l-0.286-0.638c-2e-3 -5e-3 -3e-3 -0.01-3e-3 -0.015 0-0.011 4e-3 -0.02 0.013-0.027 9e-3 -6e-3 0.018-0.01 0.029-0.01 0.016 0 0.028 8e-3 0.035 0.023l0.258 0.583 0.257-0.583z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(20 0 0 20 63.513 112)">
          <path
            d="m0.128 5e-3c-0.013 0-0.023-4e-3 -0.03-0.012-7e-3 -7e-3 -0.011-0.017-0.011-0.03v-0.631c0-0.013 4e-3 -0.023 0.011-0.031 7e-3 -7e-3 0.017-0.011 0.03-0.011s0.023 4e-3 0.03 0.011c7e-3 8e-3 0.011 0.018 0.011 0.031v0.631c0 0.013-4e-3 0.023-0.011 0.03-7e-3 8e-3 -0.017 0.012-0.03 0.012z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 4.5674 121.05)">
          <path
            d="m0.531-0.509c0 0.069-0.024 0.122-0.071 0.159-0.046 0.037-0.113 0.055-0.199 0.055h-0.11v0.295h-0.05v-0.714h0.175c0.17 0 0.255 0.068 0.255 0.205zm-0.38 0.171h0.098c0.08 0 0.139-0.013 0.174-0.04 0.036-0.026 0.054-0.069 0.054-0.129 0-0.055-0.017-0.096-0.051-0.122-0.034-0.027-0.086-0.04-0.157-0.04h-0.118v0.331z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 9.2472 121.05)">
          <path
            d="m0.298-0.541c0.022 0 0.046 2e-3 0.072 7e-3l-9e-3 0.046c-0.022-5e-3 -0.045-8e-3 -0.069-8e-3 -0.045 0-0.082 0.019-0.111 0.058-0.029 0.038-0.044 0.086-0.044 0.145v0.293h-0.048v-0.531h0.041l5e-3 0.096h3e-3c0.022-0.039 0.045-0.067 0.07-0.082 0.025-0.016 0.055-0.024 0.09-0.024z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 12.361 121.05)">
          <path
            d="m0.411-0-0.012-0.084h-4e-3c-0.027 0.034-0.054 0.058-0.082 0.073-0.028 0.014-0.062 0.021-0.1 0.021-0.052 0-0.093-0.014-0.122-0.04-0.029-0.027-0.043-0.064-0.043-0.111 0-0.052 0.021-0.092 0.065-0.121 0.043-0.028 0.105-0.044 0.187-0.045l0.101-3e-3v-0.035c0-0.051-0.01-0.089-0.031-0.114-0.02-0.026-0.053-0.039-0.099-0.039-0.049 0-0.1 0.014-0.153 0.041l-0.018-0.042c0.058-0.027 0.116-0.041 0.173-0.041 0.058 0 0.102 0.015 0.131 0.045 0.028 0.031 0.043 0.078 0.043 0.142v0.353h-0.036zm-0.195-0.034c0.056 0 0.101-0.016 0.134-0.049 0.033-0.032 0.049-0.077 0.049-0.135v-0.052l-0.093 4e-3c-0.074 3e-3 -0.128 0.015-0.159 0.035-0.032 0.019-0.048 0.05-0.048 0.092 0 0.033 0.01 0.059 0.031 0.077 0.02 0.019 0.049 0.028 0.086 0.028z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 16.599 121.05)">
          <path
            d="m0.301 0.01c-0.076 0-0.135-0.024-0.178-0.072s-0.065-0.115-0.065-0.2c0-0.088 0.022-0.157 0.067-0.206s0.106-0.073 0.183-0.073c0.046 0 0.09 8e-3 0.132 0.024l-0.013 0.043c-0.046-0.015-0.086-0.023-0.12-0.023-0.065 0-0.114 0.02-0.148 0.06-0.033 0.04-0.05 0.098-0.05 0.174 0 0.071 0.017 0.128 0.05 0.168 0.034 0.041 0.081 0.061 0.141 0.061 0.048 0 0.093-9e-3 0.134-0.026v0.045c-0.034 0.016-0.078 0.025-0.133 0.025z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 20.4 121.05)">
          <path
            d="m0.528-0.266c0 0.086-0.021 0.154-0.063 0.203-0.042 0.048-0.1 0.073-0.174 0.073-0.047 0-0.088-0.011-0.123-0.034-0.036-0.022-0.063-0.055-0.082-0.097-0.018-0.042-0.028-0.09-0.028-0.145 0-0.087 0.021-0.154 0.063-0.203 0.042-0.048 0.1-0.072 0.173-0.072s0.13 0.024 0.172 0.073c0.041 0.049 0.062 0.117 0.062 0.202zm-0.419 0c0 0.073 0.016 0.13 0.048 0.171 0.032 0.04 0.077 0.061 0.136 0.061s0.104-0.021 0.136-0.061c0.032-0.041 0.048-0.098 0.048-0.171s-0.016-0.13-0.049-0.171c-0.032-0.04-0.077-0.06-0.136-0.06s-0.104 0.02-0.136 0.06c-0.031 0.04-0.047 0.097-0.047 0.171z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 25.088 121.05)">
          <path
            d="m0.513-0-0.116-0.355c-8e-3 -0.024-0.017-0.059-0.029-0.106h-3e-3l-0.01 0.036-0.022 0.071-0.118 0.354h-0.048l-0.152-0.531h0.052l0.085 0.308c0.02 0.076 0.033 0.132 0.039 0.168h3e-3c0.019-0.076 0.033-0.127 0.042-0.152l0.109-0.324h0.044l0.104 0.323c0.024 0.076 0.038 0.127 0.043 0.152h3e-3c3e-3 -0.021 0.016-0.078 0.039-0.17l0.081-0.305h0.049l-0.144 0.531h-0.051z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 30.873 121.05)">
          <path
            d="m0.459-0v-0.344c0-0.054-0.011-0.092-0.034-0.117-0.022-0.024-0.057-0.036-0.104-0.036-0.064 0-0.11 0.016-0.14 0.048-0.029 0.032-0.044 0.084-0.044 0.156v0.293h-0.048v-0.531h0.041l9e-3 0.073h3e-3c0.035-0.055 0.096-0.083 0.184-0.083 0.121 0 0.181 0.065 0.181 0.194v0.347h-0.048z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 35.592 121.05)">
          <path
            d="m0.137-0h-0.048v-0.531h0.048v0.531zm-0.055-0.678c0-0.031 0.01-0.047 0.031-0.047 0.01 0 0.018 4e-3 0.023 0.012 6e-3 8e-3 9e-3 0.02 9e-3 0.035 0 0.014-3e-3 0.026-9e-3 0.034-5e-3 9e-3 -0.013 0.013-0.023 0.013-0.021 0-0.031-0.016-0.031-0.047z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 37.401 121.05)">
          <path
            d="m0.411-0-0.012-0.084h-4e-3c-0.027 0.034-0.054 0.058-0.082 0.073-0.028 0.014-0.062 0.021-0.1 0.021-0.052 0-0.093-0.014-0.122-0.04-0.029-0.027-0.043-0.064-0.043-0.111 0-0.052 0.021-0.092 0.065-0.121 0.043-0.028 0.105-0.044 0.187-0.045l0.101-3e-3v-0.035c0-0.051-0.01-0.089-0.031-0.114-0.02-0.026-0.053-0.039-0.099-0.039-0.049 0-0.1 0.014-0.153 0.041l-0.018-0.042c0.058-0.027 0.116-0.041 0.173-0.041 0.058 0 0.102 0.015 0.131 0.045 0.028 0.031 0.043 0.078 0.043 0.142v0.353h-0.036zm-0.195-0.034c0.056 0 0.101-0.016 0.134-0.049 0.033-0.032 0.049-0.077 0.049-0.135v-0.052l-0.093 4e-3c-0.074 3e-3 -0.128 0.015-0.159 0.035-0.032 0.019-0.048 0.05-0.048 0.092 0 0.033 0.01 0.059 0.031 0.077 0.02 0.019 0.049 0.028 0.086 0.028z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 43.721 121.05)">
          <path
            d="m0.531-0.509c0 0.069-0.024 0.122-0.071 0.159-0.046 0.037-0.113 0.055-0.199 0.055h-0.11v0.295h-0.05v-0.714h0.175c0.17 0 0.255 0.068 0.255 0.205zm-0.38 0.171h0.098c0.08 0 0.139-0.013 0.174-0.04 0.036-0.026 0.054-0.069 0.054-0.129 0-0.055-0.017-0.096-0.051-0.122-0.034-0.027-0.086-0.04-0.157-0.04h-0.118v0.331z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 48.4 121.05)">
          <path
            d="m0.298-0.541c0.022 0 0.046 2e-3 0.072 7e-3l-9e-3 0.046c-0.022-5e-3 -0.045-8e-3 -0.069-8e-3 -0.045 0-0.082 0.019-0.111 0.058-0.029 0.038-0.044 0.086-0.044 0.145v0.293h-0.048v-0.531h0.041l5e-3 0.096h3e-3c0.022-0.039 0.045-0.067 0.07-0.082 0.025-0.016 0.055-0.024 0.09-0.024z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 51.514 121.05)">
          <path
            d="m0.528-0.266c0 0.086-0.021 0.154-0.063 0.203-0.042 0.048-0.1 0.073-0.174 0.073-0.047 0-0.088-0.011-0.123-0.034-0.036-0.022-0.063-0.055-0.082-0.097-0.018-0.042-0.028-0.09-0.028-0.145 0-0.087 0.021-0.154 0.063-0.203 0.042-0.048 0.1-0.072 0.173-0.072s0.13 0.024 0.172 0.073c0.041 0.049 0.062 0.117 0.062 0.202zm-0.419 0c0 0.073 0.016 0.13 0.048 0.171 0.032 0.04 0.077 0.061 0.136 0.061s0.104-0.021 0.136-0.061c0.032-0.041 0.048-0.098 0.048-0.171s-0.016-0.13-0.049-0.171c-0.032-0.04-0.077-0.06-0.136-0.06s-0.104 0.02-0.136 0.06c-0.031 0.04-0.047 0.097-0.047 0.171z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 56.202 121.05)">
          <path
            d="m0.018 0.24c-0.026 0-0.048-4e-3 -0.066-0.012v-0.042c0.023 7e-3 0.044 0.01 0.063 0.01 0.049 0 0.074-0.029 0.074-0.086v-0.641h0.048v0.634c0 0.044-0.01 0.078-0.031 0.102-0.02 0.023-0.05 0.035-0.088 0.035zm0.064-0.918c0-0.031 0.01-0.047 0.031-0.047 0.01 0 0.018 4e-3 0.023 0.012 6e-3 8e-3 9e-3 0.02 9e-3 0.035 0 0.014-3e-3 0.026-9e-3 0.034-5e-3 9e-3 -0.013 0.013-0.023 0.013-0.021 0-0.031-0.016-0.031-0.047z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 58.01 121.05)">
          <path
            d="m0.303 0.01c-0.077 0-0.137-0.024-0.18-0.072-0.043-0.047-0.065-0.114-0.065-0.199s0.021-0.153 0.063-0.204c0.041-0.051 0.097-0.076 0.168-0.076 0.063 0 0.112 0.022 0.148 0.065 0.036 0.044 0.054 0.103 0.054 0.178v0.039h-0.382c1e-3 0.073 0.018 0.129 0.051 0.167 0.033 0.039 0.081 0.058 0.143 0.058 0.03 0 0.057-2e-3 0.08-7e-3 0.023-4e-3 0.052-0.013 0.087-0.027v0.044c-0.03 0.013-0.057 0.022-0.083 0.027-0.025 4e-3 -0.053 7e-3 -0.084 7e-3zm-0.014-0.508c-0.051 0-0.092 0.017-0.123 0.05-0.031 0.034-0.049 0.083-0.054 0.146h0.328c0-0.061-0.013-0.109-0.04-0.144s-0.064-0.052-0.111-0.052z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 62.401 121.05)">
          <path
            d="m0.136-0.233 0.271-0.298h0.059l-0.209 0.227 0.227 0.304h-0.058l-0.202-0.268-0.087 0.079v0.189h-0.048v-0.76h0.048v0.381l-3e-3 0.146h2e-3z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 66.272 121.05)">
          <path
            d="m0.229-0.033c0.031 0 0.057-3e-3 0.08-8e-3v0.039c-0.023 8e-3 -0.05 0.012-0.081 0.012-0.047 0-0.081-0.013-0.104-0.038-0.022-0.025-0.033-0.064-0.033-0.118v-0.343h-0.079v-0.028l0.079-0.022 0.024-0.12h0.025v0.128h0.156v0.042h-0.156v0.336c0 0.041 7e-3 0.071 0.022 0.09 0.014 0.02 0.036 0.03 0.067 0.03z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 68.952 121.05)">
          <path
            d="m0.528-0.266c0 0.086-0.021 0.154-0.063 0.203-0.042 0.048-0.1 0.073-0.174 0.073-0.047 0-0.088-0.011-0.123-0.034-0.036-0.022-0.063-0.055-0.082-0.097-0.018-0.042-0.028-0.09-0.028-0.145 0-0.087 0.021-0.154 0.063-0.203 0.042-0.048 0.1-0.072 0.173-0.072s0.13 0.024 0.172 0.073c0.041 0.049 0.062 0.117 0.062 0.202zm-0.419 0c0 0.073 0.016 0.13 0.048 0.171 0.032 0.04 0.077 0.061 0.136 0.061s0.104-0.021 0.136-0.061c0.032-0.041 0.048-0.098 0.048-0.171s-0.016-0.13-0.049-0.171c-0.032-0.04-0.077-0.06-0.136-0.06s-0.104 0.02-0.136 0.06c-0.031 0.04-0.047 0.097-0.047 0.171z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 73.64 121.05)">
          <path
            d="m0.513-0-0.116-0.355c-8e-3 -0.024-0.017-0.059-0.029-0.106h-3e-3l-0.01 0.036-0.022 0.071-0.118 0.354h-0.048l-0.152-0.531h0.052l0.085 0.308c0.02 0.076 0.033 0.132 0.039 0.168h3e-3c0.019-0.076 0.033-0.127 0.042-0.152l0.109-0.324h0.044l0.104 0.323c0.024 0.076 0.038 0.127 0.043 0.152h3e-3c3e-3 -0.021 0.016-0.078 0.039-0.17l0.081-0.305h0.049l-0.144 0.531h-0.051z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g transform="matrix(8 0 0 8 79.425 121.05)">
          <path
            d="m0.411-0-0.012-0.084h-4e-3c-0.027 0.034-0.054 0.058-0.082 0.073-0.028 0.014-0.062 0.021-0.1 0.021-0.052 0-0.093-0.014-0.122-0.04-0.029-0.027-0.043-0.064-0.043-0.111 0-0.052 0.021-0.092 0.065-0.121 0.043-0.028 0.105-0.044 0.187-0.045l0.101-3e-3v-0.035c0-0.051-0.01-0.089-0.031-0.114-0.02-0.026-0.053-0.039-0.099-0.039-0.049 0-0.1 0.014-0.153 0.041l-0.018-0.042c0.058-0.027 0.116-0.041 0.173-0.041 0.058 0 0.102 0.015 0.131 0.045 0.028 0.031 0.043 0.078 0.043 0.142v0.353h-0.036zm-0.195-0.034c0.056 0 0.101-0.016 0.134-0.049 0.033-0.032 0.049-0.077 0.049-0.135v-0.052l-0.093 4e-3c-0.074 3e-3 -0.128 0.015-0.159 0.035-0.032 0.019-0.048 0.05-0.048 0.092 0 0.033 0.01 0.059 0.031 0.077 0.02 0.019 0.049 0.028 0.086 0.028z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
      </g>
      <g transform="matrix(4.1667 0 0 4.1667 -19.031 29.662)">
        <g transform="translate(63.907 76.254)">
          <path
            d="m0-58.879-20.019 24.053-20.017-24.053c5.705-3.89 12.597-6.165 20.017-6.165 7.421 0 14.314 2.275 20.019 6.165m-41.581 1.113c-8.531 6.506-14.043 16.778-14.043 28.327 0 11.958 5.911 22.55 14.964 29.006v-40.713c0-0.4 0.25-0.755 0.625-0.893 0.375-0.136 0.796-0.022 1.053 0.282l18.999 22.687c3.551-4.265 18.879-22.683 18.879-22.683 0.256-0.306 0.675-0.422 1.054-0.286 0.374 0.136 0.625 0.493 0.625 0.893v40.746c9.083-6.455 15.012-17.059 15.012-29.039 0-11.549-5.513-21.821-14.044-28.327l-20.832 25.033c-0.18 0.214-0.447 0.341-0.73 0.341-0.281 0-0.549-0.127-0.729-0.341l-20.833-25.033zm2.819 58.597c1.868 1.159 3.851 2.151 5.926 2.953v-21.351c0-0.4 0.249-0.755 0.626-0.893 0.373-0.135 0.795-0.022 1.05 0.283l11.845 14.14c0.336 0.401 0.283 1.002-0.119 1.338-0.401 0.337-1 0.282-1.337-0.117l-10.166-12.139v19.41c3.441 1.111 7.111 1.71 10.918 1.71 3.79 0 7.445-0.593 10.872-1.692v-22.04c0-0.526 0.425-0.95 0.948-0.95s0.949 0.424 0.949 0.95v21.371c2.078-0.8 4.06-1.787 5.927-2.942v-39.385l-17.927 21.541c-0.179 0.216-0.446 0.341-0.729 0.342-0.281 0-0.548-0.124-0.73-0.34l-18.053-21.554v39.365zm18.743-67.944c20.792 0 37.674 16.881 37.674 37.674 0 20.791-16.882 37.673-37.674 37.673-20.793 0-37.673-16.882-37.673-37.673 0-20.793 16.88-37.674 37.673-37.674"
            fill="#000"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default LogoVertical

import { stripHexcode } from 'emojibase'
import { PathConfig, useEmojiData } from 'interweave-emoji'
import { useMemo } from 'react'

const getEmojiPath = (hexcode: string, { enlarged }: PathConfig): string =>
  `https://cdn.jsdelivr.net/gh/joypixels/emoji-assets@latest/png/${
    enlarged ? 64 : 32
  }/${stripHexcode(hexcode).toLowerCase()}.png`

export const useEmojis = () => {
  const [_, source] = useEmojiData({
    compact: false,
    shortcodes: ['cldr', 'emojibase'],
  })
  return useMemo(
    () => ({
      emojiSize: '1em',
      emojiLargeSize: '3em',
      emojiSource: source,
      emojiPath: getEmojiPath,
    }),
    [source]
  )
}

import config from 'config'
import Helmet, { HelmetProps } from 'react-helmet'
import { SiteMetadata, useSiteMetadata } from './SEO.utils'

interface SEOProps extends Partial<SiteMetadata> {
  keywords?: string[]
  meta?: HelmetProps['meta']
}

const SEO = ({ meta = [], ...props }: SEOProps) => {
  const siteMetadata = useSiteMetadata()
  const {
    title,
    image,
    siteUrl = siteMetadata.siteUrl,
    author = siteMetadata.author,
    titleTemplate = siteMetadata.titleTemplate,
    description = siteMetadata.description,
    lang = siteMetadata.lang,
    keywords = siteMetadata.keywords || [],
    logo = siteMetadata.logo,
    jsonLd = siteMetadata.jsonLd || {
      localBusiness: '',
      webPage: '',
    },
  } = props
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={titleTemplate}
      defaultTitle={siteMetadata.title}
      link={[{ rel: 'shortcut icon', href: '/favicon.ico' }]}
      meta={[
        {
          name: 'facebook-domain-verification',
          content: config.facebookDomainVerification,
        },
        { name: 'description', content: description },
        { property: 'og:image', content: image || logo },
        {
          property: 'og:title',
          content: title
            ? `${title} | ${siteMetadata.title}`
            : siteMetadata.title,
        },
        { property: 'og:description', content: description },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: siteUrl },
        { property: 'og:locale', content: lang },
        { name: 'twitter:image', content: image || logo },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:creator', content: author },
        {
          name: 'twitter:title',
          content: title
            ? `${title} | ${siteMetadata.title}`
            : siteMetadata.title,
        },
        { name: 'twitter:description', content: description },
        ...(keywords.length > 0
          ? [{ name: 'keywords', content: keywords.join(', ') }]
          : []),
        ...meta,
      ]}
    >
      {jsonLd.localBusiness && (
        <script type="application/ld+json">{jsonLd.localBusiness}</script>
      )}
      {jsonLd.webPage && (
        <script type="application/ld+json">{jsonLd.webPage}</script>
      )}
    </Helmet>
  )
}

export default SEO

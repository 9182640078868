import breakpoints from './breakpoints'
import mixins from './mixins'
import palette from './palette'
import spacing from './spacing'
import transition from './transition'
import typography from './typography'
import zIndex from './zIndex'

const theme = <const>{
  breakpoints,
  mixins,
  palette,
  spacing,
  transition,
  typography,
  zIndex,
}

export const fadeInAnimation = {
  'data-sal': 'slide-up',
  'data-sal-duration': 500,
  'data-sal-easing': transition.timingFunction,
}

export type Theme = typeof theme

declare module 'styled-components' {
  interface DefaultTheme extends Theme {}
}

export { breakpoints } from './breakpoints'
export type { Breakpoint } from './breakpoints'
export default theme

import { BLOCKS } from '@contentful/rich-text-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import Button from '../Button'
import { useCookies, CookiesStatus } from '../CookiesContextProvider'
import RichText from '../RichText'
import Typography from '../Typography'
import { Container, ButtonsContainer } from './CookieBanner.styles'
import { useCookieBanner } from './CookieBanner.utils'

const VISIBILITY_TIMEOUT = 250

interface CookieBannerProps {}

const CookieBanner = (props: CookieBannerProps) => {
  const { description, acceptButtonLabel, rejectButtonLabel } =
    useCookieBanner()
  const { status, acceptCookies, rejectCookies } = useCookies()
  const prevStatus = useRef(status)
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    if (!prevStatus.current) {
      prevStatus.current = status
      setIsVisible(!status || status === CookiesStatus.Unknown)
    }
  }, [status])

  const handleAccept = useCallback(() => {
    acceptCookies()
    setTimeout(() => setIsVisible(false), VISIBILITY_TIMEOUT)
  }, [acceptCookies])

  const handleReject = useCallback(() => {
    rejectCookies()
    setTimeout(() => setIsVisible(false), VISIBILITY_TIMEOUT)
  }, [rejectCookies])

  return isVisible ? (
    <Container $in={status === CookiesStatus.Unknown} {...props}>
      <RichText
        content={description}
        nodeOptions={{
          [BLOCKS.PARAGRAPH]: (_, children) => (
            <Typography variant="body2" align="center">
              {children}
            </Typography>
          ),
        }}
      />
      <ButtonsContainer>
        <Button variant="outlined" onClick={handleReject}>
          {rejectButtonLabel}
        </Button>
        <Button variant="filled" onClick={handleAccept}>
          {acceptButtonLabel}
        </Button>
      </ButtonsContainer>
    </Container>
  ) : null
}

export default CookieBanner

import { graphql, useStaticQuery } from 'gatsby'

interface Query {
  cookieBanner: {
    id: string
    description: { raw: string } | null
    acceptButtonLabel: string | null
    rejectButtonLabel: string | null
  }
}

export const useCookieBanner = () => {
  const {
    cookieBanner: { description, acceptButtonLabel, rejectButtonLabel },
  } = useStaticQuery<Query>(graphql`
    {
      cookieBanner: contentfulBanerCiasteczka {
        id
        acceptButtonLabel
        rejectButtonLabel
        description {
          raw
        }
      }
    }
  `)

  return {
    acceptButtonLabel,
    rejectButtonLabel,
    description: description?.raw || '',
  }
}

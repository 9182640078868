import styled from 'styled-components'
import Button from '../Button'

export const Container = styled.footer`
  margin: 0 auto;
  box-sizing: border-box;
  background-color: ${p => p.theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const BackToTopButton = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${p => p.theme.spacing(1)};

  ${p => p.theme.breakpoints.up('md')} {
    right: 0;
  }
`

export const ContentContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: ${p => p.theme.spacing(6)};
  width: 100%;
  max-width: ${p => p.theme.breakpoints.md}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;

  & > *:not(:last-child):not(${BackToTopButton}) {
    margin-bottom: ${p => p.theme.spacing(3)};
  }

  ${p => p.theme.breakpoints.down('xs')} {
    padding: ${p => p.theme.spacing(4, 2)};

    & > *:not(:last-child) {
      margin-bottom: ${p => p.theme.spacing(1)};
    }
  }
`

export const MainMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: ${p => p.theme.spacing(2)};
  }
`

export const SecondaryMenusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SecondaryMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: ${p => p.theme.spacing(1)};
  }
  & > *:not(:last-child) {
    margin-right: ${p => p.theme.spacing(2)};
  }

  ${p => p.theme.breakpoints.down('xs')} {
    flex-direction: column;
    align-items: center;

    & > * {
      margin-bottom: ${p => p.theme.spacing(1)};
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
`

import Typography from 'typography'
import palette from './palette'

const typography = new Typography({
  baseFontSize: '16px',
  scaleRatio: 3,
  headerFontFamily: ['Playfair Display', 'Times New Roman', 'serif'],
  bodyFontFamily: ['Open Sans', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
  headerColor: palette.text.primary,
  bodyColor: palette.text.primary,
  headerWeight: '400',
  bodyWeight: '300',
  boldWeight: '700',
  headerLineHeight: 1.33,
  baseLineHeight: 2,
})

export const { scale, rhythm, options } = typography
export default typography

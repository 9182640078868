import { ChevronUp } from 'react-feather'
import { animateScroll } from 'react-scroll'
import FooterItem, { FooterItemProps } from './FooterItem'
import {
  Container,
  ContentContainer,
  MainMenu,
  SecondaryMenu,
  SecondaryMenusContainer,
  BackToTopButton,
} from './Footer.style'
import RichText from '../RichText'

export interface FooterProps {
  menus: FooterItemProps[][]
  copyright: string
}

const Footer = ({ menus, copyright, ...props }: FooterProps) => {
  const [mainMenu, ...secondaryMenus] = menus

  return (
    <Container {...props}>
      <ContentContainer>
        <BackToTopButton
          roundIcon
          color="secondary"
          onClick={() =>
            animateScroll.scrollToTop({ smooth: 'easeInOutQuint' })
          }
          aria-label="Wróć do góry"
        >
          <ChevronUp />
        </BackToTopButton>
        <MainMenu>
          {mainMenu.map((item, i) => (
            <FooterItem key={`footer-main-${i}`} {...item} />
          ))}
        </MainMenu>
        <SecondaryMenusContainer>
          {secondaryMenus.map((menu, i) => (
            <SecondaryMenu key={`footer-secondary-${i}`}>
              {menu.map((item, j) => (
                <FooterItem key={`footer-secondary-item-${j}`} {...item} />
              ))}
            </SecondaryMenu>
          ))}
        </SecondaryMenusContainer>
        <RichText
          content={copyright}
          typographyProps={{
            variant: 'body2',
            color: 'muted',
            align: 'center',
          }}
        />
      </ContentContainer>
    </Container>
  )
}

export default Footer

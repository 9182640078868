import { Link } from 'gatsby'
import { HTMLAttributes } from 'react'
import { animateScroll } from 'react-scroll'

export interface HashtagProps extends HTMLAttributes<HTMLAnchorElement> {
  hashtag: string
  hashtagUrl?: string | ((hashtag: string) => string)
}

const Hashtag = ({
  children,
  hashtag,
  hashtagUrl = '{{hashtag}}',
}: HashtagProps) => {
  const query = hashtag.replace('#', '')
  return (
    <Link
      onClick={() => animateScroll.scrollToTop({ smooth: 'easeInOutQuint' })}
      to={
        typeof hashtagUrl === 'string'
          ? hashtagUrl.replace('{{hashtag}}', query)
          : hashtagUrl(query)
      }
    >
      {children}
    </Link>
  )
}

export default Hashtag

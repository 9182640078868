import SwiperCore, { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useState } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { useAnalytics, useIsMobile } from '../../utils'
import Button from '../Button'
import TestimonialItem, { TestimonialsItemProps } from './TestimonialsItem'
import { ButtonsContainer, Container } from './Testimonials.style'

const SWIPER_SPACING = 20
const SWIPER_COLUMNS_MOBILE = 1
const SWIPER_COLUMNS_DESKTOP = 2

SwiperCore.use([Controller])

export interface TestimonialsProps {
  items: TestimonialsItemProps[]
}

const Testimonials = ({ items, ...props }: TestimonialsProps) => {
  const analytics = useAnalytics()
  const isMobile = useIsMobile('sm')
  const [controlledSwiper, setControlledSwiper] = useState<SwiperCore>()
  const [activeIndex, setActiveIndex] = useState(0)

  const handleSlideChange = (swiper: SwiperCore) => {
    analytics.focusTestimonial(swiper.activeIndex)
    setActiveIndex(swiper.activeIndex)
  }
  const slidesPerView = isMobile
    ? SWIPER_COLUMNS_MOBILE
    : SWIPER_COLUMNS_DESKTOP

  const isPrevDisabled = activeIndex === 0
  const isNextDisabled = activeIndex + slidesPerView === items.length

  return (
    <Container {...props}>
      <Swiper
        controller={{ control: controlledSwiper }}
        slidesPerView={slidesPerView}
        spaceBetween={SWIPER_SPACING}
        onSwiper={setControlledSwiper}
        onSlideChange={handleSlideChange}
      >
        {items.map((item, i) => (
          <SwiperSlide key={`testimonial-${i}`}>
            <TestimonialItem
              {...item}
              onClick={() => analytics.toggleTestimonialComment(i)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <ButtonsContainer>
        <Button
          aria-label="Poprzednia"
          roundIcon
          disabled={isPrevDisabled}
          onClick={() => controlledSwiper?.slidePrev()}
        >
          <ArrowLeft />
        </Button>
        <Button
          roundIcon
          aria-label="Następna"
          disabled={isNextDisabled}
          onClick={() => controlledSwiper?.slideNext()}
        >
          <ArrowRight />
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

export default Testimonials

export { default as paths } from './paths'
export { breakpoints, fadeInAnimation, default as theme } from './theme'
export type { Breakpoint, Theme } from './theme'

const config = {
  cookiesStatusKey: 'cookiesStatus',
  contactFormId: 'contact-form',
  facebookDomainVerification: process.env.GATSBY_FACEBOOK_DOMAIN_VERIFICATION,
  contactFormUrl: process.env.GATSBY_CONTACT_FORM_ENDPOINT || '',
  metaPixelId: process.env.GATSBY_META_PIXEL_ID || '',
  firebaseConfig: JSON.parse(process.env.GATSBY_FIREBASE_CONFIG || '{}'),
  projectIdRegex: /^(\d.+|.*konkurs.*)/i,
  emailRegex:
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
}

export default config

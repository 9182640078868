import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import {
  CookiesContextProvider,
  FirebaseContextProvider,
  MetaPixel,
} from './src/shared/components'

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
    <CookiesContextProvider>
      <FirebaseContextProvider>
        <MetaPixel />
        {element}
      </FirebaseContextProvider>
    </CookiesContextProvider>
  </GoogleReCaptchaProvider>
)

import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${p => p.theme.breakpoints.up('md')} {
    &:first-of-type {
      & > * {
        border-radius: 2px 0 0 2px;
      }
    }

    &:last-of-type {
      & > * {
        border-radius: 0 2px 2px 0;
      }
    }
  }

  ${p => p.theme.breakpoints.down('md')} {
    &:first-of-type {
      & > * {
        border-radius: 2px 2px 0 0;
      }
    }

    &:last-of-type {
      & > * {
        border-radius: 0 0 2px 2px;
      }
    }
  }
`

interface CardProps {
  $active?: boolean
}

export const Card = styled.div<CardProps>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  background-color: ${p => p.theme.palette.background.primary};
  background-position: 0 0, 1px 1px;
  background-size: 10px 10px;
  background-image: radial-gradient(
    ${p => p.theme.palette.fade(p.theme.palette.common.black, 0.15)} 10%,
    transparent 10%
  );
  box-shadow: none;
  outline: 1px solid ${p => p.theme.palette.background.muted};
  transition: ${p =>
    p.theme.transition.create(['transform', 'outline', 'box-shadow'])};

  ${p => p.theme.breakpoints.up('md')} {
    &:hover {
      cursor: pointer;
      transform: translateY(${p => p.theme.spacing(-1)});
    }
  }

  ${p => p.theme.breakpoints.down('md')} {
    pointer-events: none;
  }

  ${p =>
    p.$active &&
    css`
      z-index: 20;
      transition: ${p => p.theme.transition.create('transform')};
      background-color: ${p => p.theme.palette.common.black};
      background-position: 0 0, 1px 1px;
      background-size: 10px 10px;
      background-image: radial-gradient(
        ${p => p.theme.palette.fade(p.theme.palette.common.white, 0.2)} 10%,
        transparent 10%
      );
      box-shadow: 0px 0px 15px
        ${p => p.theme.palette.fade(p.theme.palette.common.black, 0.25)};
      outline: 1px solid ${p => p.theme.palette.text.dark};

      ${p => p.theme.breakpoints.up('md')} {
        border-radius: 2px;
        transform: translateY(${p => p.theme.spacing(-1.5)});
        &:hover {
          cursor: pointer;
          transform: translateY(${p => p.theme.spacing(-2.5)});
        }
      }

      & ${Header} {
        border-radius: 2px 2px 0 0;
        background-color: ${p => p.theme.palette.common.black};
        color: ${p => p.theme.palette.text.secondary};
      }

      & ${Subheader} {
        background-color: ${p => p.theme.palette.background.dark};
        color: ${p => p.theme.palette.text.secondary};
        outline: 1px solid ${p => p.theme.palette.text.dark};
      }

      & ${Attributes} {
        color: ${p => p.theme.palette.text.secondary};
      }
    `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  box-sizing: border-box;
  height: 125px;
  padding: ${p => p.theme.spacing(1.5)};
  background-color: ${p => p.theme.palette.background.primary};
  color: ${p => p.theme.palette.text.primary};
  & * {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  & > * {
    line-height: 1.5;
  }
`

export const Subheader = styled.div`
  box-sizing: border-box;
  height: 80px;
  padding: ${p => p.theme.spacing(1.5, 1.5, 3)};
  background-color: ${p => p.theme.palette.background.light};
  color: ${p => p.theme.palette.text.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 10;
  outline: 1px solid ${p => p.theme.palette.background.muted};

  & > *:first-child {
    font-weight: 300;
    font-size: 0.625rem;
  }
`

export const CallToAction = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
  z-index: 10;
  width: 100%;
  padding: ${p => p.theme.spacing(0, 1.5)};
  & > * {
    max-width: 250px;
    width: 100%;
  }

  ${p => p.theme.breakpoints.down('md')} {
    pointer-events: auto;
  }
`

export const Attributes = styled.div`
  position: relative;
  flex: 1;
  padding: ${p => p.theme.spacing(3, 1.5, 1.5)};
  & * {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
  & ul {
    list-style: disc;
    margin-left: ${p => p.theme.spacing(1.5)};
  }
`

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { Analytics, getAnalytics } from 'firebase/analytics'
import config from 'config'
import { CookiesStatus, useCookies } from '../CookiesContextProvider'

const app = initializeApp(config.firebaseConfig)
const defaultAnalytics = { app }

export interface FirebaseContextValue {
  app: FirebaseApp
  analytics: Analytics
}

export const defaultValue: FirebaseContextValue = {
  app,
  analytics: defaultAnalytics,
}

export const FirebaseContext = createContext(defaultValue)

export const useFirebase = () => useContext(FirebaseContext)

export const useFirebaseContext = (): FirebaseContextValue => {
  const { status } = useCookies()
  const [analytics, setAnalytics] = useState<Analytics>(defaultAnalytics)

  useEffect(() => {
    if (status === CookiesStatus.Accepted) {
      setAnalytics(getAnalytics(app))
    }
  }, [status])

  return {
    ...defaultValue,
    analytics,
  }
}

import styled from 'styled-components'

export const Container = styled.div`
  ${p => p.theme.breakpoints.down('md')} {
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: ${p => p.theme.spacing(3)};
  }

  ${p => p.theme.breakpoints.up('md')} {
    margin-bottom: ${p => p.theme.spacing(2)};
    padding-top: ${p => p.theme.spacing(1.5)};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }

  & > * {
    display: flex;
    width: 100%;
  }
`

import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import styled, { css, keyframes } from 'styled-components'

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: ${(p) => p.theme.transition.create('opacity')};
  background-color: ${(p) =>
    p.theme.palette.fade(p.theme.palette.common.black, 0.65)};
  padding: ${(p) => p.theme.spacing(2)};
  text-transform: uppercase;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  pointer-events: none;

  ${(p) => p.theme.breakpoints.down('xs')} {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
`

export const Likes = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${(p) => p.theme.palette.secondary.main};
  padding: ${(p) => p.theme.spacing(2)};
  font-size: 1rem;
  svg {
    width: 1rem;
    height: 1rem;
    margin-right: ${(p) => p.theme.spacing(0.5)};
    fill: ${(p) => p.theme.palette.secondary.main};
  }
`

export const Image = styled(GatsbyImage)`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const galleryItemSize = {
  md: '240px',
  sm: '50%',
}

export const galleryItemStyle = css`
  display: inline-block;
  position: relative;
  width: ${galleryItemSize.sm};
  height: auto;
  aspect-ratio: 1 / 1;
  line-height: ${(p) => p.theme.typography.rhythm(0.75)};

  ${(p) => p.theme.breakpoints.up('md')} {
    width: ${galleryItemSize.md};
    height: ${galleryItemSize.md};
  }
`

export interface ItemContainerProps {
  $image?: string
}

export const ItemContainer = styled(Link)<ItemContainerProps>`
  ${galleryItemStyle};

  &:hover {
    ${Overlay} {
      opacity: 1;
    }
  }
`

const pulse = keyframes`
  0% { opacity: 0.05; }
  50% { opacity: 0.1; }
  100% { opacity: 0.05; }
`

export const ItemLoader = styled.div`
  ${galleryItemStyle};

  opacity: 0.05;
  background-color: ${(p) => p.theme.palette.common.black};
  animation: ${pulse} 1.5s ease-in-out infinite;

  ${(p) => p.theme.breakpoints.down('md')} {
    &:nth-child(8n),
    &:nth-child(8n + 1),
    &:nth-child(8n + 4),
    &:nth-child(8n + 5) {
      animation-delay: 0.5s;
    }
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    &:nth-child(8n + 1),
    &:nth-child(8n + 3),
    &:nth-child(8n + 6),
    &:nth-child(8n) {
      animation-delay: 0.5s;
    }
  }
`

export const ItemPlaceholder = styled.div`
  ${galleryItemStyle};

  opacity: 0.1;
  background-color: ${(p) => p.theme.palette.common.black};

  ${(p) => p.theme.breakpoints.down('md')} {
    &:nth-child(4n + 4) {
      opacity: 0.05;
    }
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    &:nth-child(odd) {
      opacity: 0.05;
    }
  }
`

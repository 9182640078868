import styled from 'styled-components'
import { QuoteSign } from '../../../svg'

export const Container = styled.div`
  position: relative;
  padding: ${p => p.theme.spacing(1, 3)};
  max-width: 470px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: ${p => p.theme.transition.create('height')};
`

export const StyledQuoteSign = styled(QuoteSign)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

export const MoreButton = styled.span`
  font-style: italic;
  color: ${p => p.theme.palette.text.light};
  &:hover {
    cursor: pointer;
  }
`

export const Author = styled.p`
  white-space: pre-wrap;
  text-align: left;
  color: inherit;
  font-size: 1rem;
  font-family: 'Playfair Display', 'Times New Roman', serif;
  font-weight: 400;
  line-height: 1.33;
  margin: 0;
  padding: 0;
`

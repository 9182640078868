import styled from 'styled-components'

export const Container = styled.div`
  ${p => p.theme.breakpoints.down('md')} {
    & > * {
      width: 100vw;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${p => p.theme.spacing(1)};
  & > * {
    margin: ${p => p.theme.spacing(0, 1)};
  }
`

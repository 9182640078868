import styled from 'styled-components'

const Section = styled.section`
  max-width: ${p => p.theme.breakpoints.md}px;
  text-align: center;
  margin: ${p => p.theme.spacing(5)} auto;

  ${p => p.theme.breakpoints.up('md')} {
    margin: ${p => p.theme.spacing(10)} auto;
  }
`

export default Section

import styled, { css } from 'styled-components'

interface ContainerProps {
  $in?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  background: ${p => p.theme.palette.background.primary};
  position: fixed;
  left: 0;
  right: 0;
  z-index: ${p => p.theme.zIndex.cookieBanner};
  width: 100%;
  padding: ${p => p.theme.spacing(2)};
  box-shadow: 0px 0px 15px
    ${p => p.theme.palette.fade(p.theme.palette.common.black, 0.15)};
  transition: ${p =>
    p.theme.transition.create([
      'top',
      'right',
      'bottom',
      'left',
      'width',
      'opacity',
    ])};

  ${p =>
    p.$in
      ? css`
          bottom: 0;
          opacity: 1;
        `
      : css`
          bottom: ${p.theme.spacing(-4)};
          opacity: 0;
          pointer-events: none;
        `}

  ${p => p.theme.breakpoints.up('sm')} {
    left: auto;
    right: ${p => p.theme.spacing(4)};
    width: 500px;
    padding: ${p => p.theme.spacing(3)};
    box-shadow: 0px 6px 15px
      ${p => p.theme.palette.fade(p.theme.palette.common.black, 0.15)};

    ${p =>
      p.$in
        ? css`
            bottom: ${p.theme.spacing(4)};
            opacity: 1;
          `
        : css`
            bottom: 0;
            opacity: 0;
          `}
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${p => p.theme.spacing(2)};
  flex: 1;
  & > *:not(:last-of-type) {
    margin-right: ${p => p.theme.spacing(2)};
  }
`

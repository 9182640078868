import { graphql, useStaticQuery } from 'gatsby'

export interface SiteMetadata {
  title: string
  titleTemplate: string
  description: string
  author: string
  siteUrl: string
  image: string
  logo: string
  lang: string
  keywords: string[]
  jsonLd: {
    localBusiness: string
    webPage: string
  }
}

export const useSiteMetadata = (): Partial<SiteMetadata> => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            titleTemplate
            description
            author
            siteUrl
            image
            logo
            lang
            keywords
            jsonLd {
              localBusiness
              webPage
            }
          }
        }
      }
    `
  )
  return site?.siteMetadata || {}
}

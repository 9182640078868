import styled, { css } from 'styled-components'
import MenuButton from './MenuButton'

const height = { initialDesktop: 120, initialMobile: 100, collapsed: 80 }

interface ContainerProps {
  $collapsed?: boolean
}

export const Container = styled.header<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${p => p.theme.zIndex.topBar};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: 'border-box';
  background-color: ${p => p.theme.palette.secondary.main};
  transition: ${p => p.theme.transition.create(['box-shadow', 'height'])};

  ${p =>
    p.$collapsed
      ? css`
          height: ${height.collapsed}px;
          padding: ${p => p.theme.spacing(2, 3)};
          box-shadow: 0px 4px 10px
            ${p.theme.palette.fade(p.theme.palette.common.black, 0.05)};
        `
      : css`
          height: ${height.initialMobile}px;
          padding: ${p => p.theme.spacing(3)};
          box-shadow: 0px 0px 0px
            ${p.theme.palette.fade(p.theme.palette.common.black, 0.0)};
        `}

  ${p => p.theme.breakpoints.up('md')} {
    ${p =>
      p.$collapsed
        ? css`
            height: ${height.collapsed}px;
            padding: ${p.theme.spacing(2, 5)};
          `
        : css`
            height: ${height.initialDesktop}px;
            padding: ${p.theme.spacing(4, 5)};
          `}
  }
`

export const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & > *:not(:first-of-type) {
    margin-left: ${p => p.theme.spacing(3)};
  }
`

interface MobileNavigation {
  $open?: boolean
  $collapsed?: boolean
}

export const MobileNavigation = styled.nav<MobileNavigation>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: ${p => p.theme.transition.create('opacity')};
  z-index: ${p => p.theme.zIndex.mobileNavigation};
  background-color: ${p => p.theme.palette.primary.main};
  color: ${p => p.theme.palette.primary.contrastText};
  padding: ${p =>
    p.$collapsed ? p.theme.spacing(8, 5, 4) : p.theme.spacing(10, 5, 4)};
  ${p =>
    p.$open
      ? css`
          opacity: 1;
          pointer-events: initial;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `};
  & > * {
    display: block;
    padding: ${p => p.theme.spacing(2, 0)};
  }
`

interface PaddingFixProps {
  $collapsed?: boolean
}

export const PaddingFix = styled.div<PaddingFixProps>`
  display: block;
  width: 100%;
  z-index: 1;
  position: relative;
  background-color: ${p => p.theme.palette.background.primary};
  height: ${height.initialMobile}px;
  ${p => p.theme.breakpoints.up('md')} {
    height: ${height.initialDesktop}px;
  }
`

interface ToggleButtonProps {
  $collapsed?: boolean
}

export const ToggleButton = styled(MenuButton)<ToggleButtonProps>`
  position: fixed;
  right: 50px;
  top: ${p => (p.$collapsed ? 30 : 40)}px;
  z-index: ${p => p.theme.zIndex.mobileNavigation + 100};
  transition: ${p => p.theme.transition.create('top')};
`

import styled, { css } from 'styled-components'
import { ChevronDown } from 'react-feather'

interface StyledSelectProps {
  $empty?: boolean
}

export const StyledSelect = styled.select<StyledSelectProps>`
  -webkit-appearance: none;
  position: relative;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${p => p.theme.palette.text.light};
  background: transparent;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: ${p =>
    p.$empty ? p.theme.palette.text.light : p.theme.palette.text.primary};
  padding: ${p => p.theme.spacing(0.5, 2, 0.5, 0)};
  margin-bottom: 0;
  transition: ${p =>
    p.theme.transition.create(['border', 'margin-bottom'], 50)};

  &::placeholder {
    color: ${p => p.theme.palette.text.light};
    opacity: 0.85;
  }

  &:focus {
    border-width: 2px;
    border-color: ${p => p.theme.palette.primary.main};
    margin-bottom: -1px;
  }
`

export const Label = styled.label`
  font-size: 0.625rem;
  line-height: 0.875rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
`

interface ContainerProps {
  $error?: boolean
  $disabled?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: ${p => p.theme.spacing(1, 0, 2.5)};
  position: relative;

  ${p =>
    p.$disabled &&
    css`
      ${Label} {
        color: ${p => p.theme.palette.text.light};
      }
      ${StyledSelect} {
        border-color: ${p => p.theme.palette.text.light};
        cursor: not-allowed;
      }
    `};

  ${p =>
    p.$error &&
    css`
      ${Label} {
        color: ${p => p.theme.palette.error.main};
      }
      ${StyledSelect} {
        border-color: ${p => p.theme.palette.error.main};
      }
    `};
`

export const ErrorLabel = styled.label`
  font-size: 0.625rem;
  line-height: 0.875rem;
  font-weight: 400;
  color: ${p => p.theme.palette.error.main};
  position: absolute;
  bottom: 0;
  left: 0;
`

export const Adornment = styled(ChevronDown)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  color: ${p => p.theme.palette.text.light};
`

import { MouseEventHandler } from 'react'
import {
  Button,
  BUTTON_HEIGHT,
  Line,
  MiddleLine,
  MenuButtonColor,
} from './MenuButton.style'

export interface MenuButtonProps {
  color?: MenuButtonColor
  open: boolean
  onClick: MouseEventHandler<HTMLDivElement>
}

const MenuButton = ({ open, color = 'primary', ...props }: MenuButtonProps) => {
  const getTransformValue = (basePos: number, rotate: number) =>
    `translate3d(0,${open ? BUTTON_HEIGHT / 2 : basePos}px,0) rotate(${
      open ? `${rotate}deg` : '0'
    })`

  return (
    <Button
      role="button"
      aria-label={open ? 'Zamknij menu' : 'Otwórz menu'}
      {...props}
    >
      <Line $color={color} style={{ transform: getTransformValue(0, 45) }} />
      <MiddleLine $color={color} $open={open} />
      <Line
        $color={color}
        style={{ transform: getTransformValue(BUTTON_HEIGHT, -45) }}
      />
    </Button>
  )
}

export default MenuButton

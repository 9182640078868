import 'swiper/swiper.scss'
import { PropsWithChildren } from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from 'config'
import { GlobalStyle } from './StyleProvider.style'

export interface StyleProviderProps {}

const StyleProvider = ({ children }: PropsWithChildren<StyleProviderProps>) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default StyleProvider

export const breakpoints = {
  xs: 480,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

export type Breakpoint = keyof typeof breakpoints

const down = (breakpoint: Breakpoint, query: string = '@media ') =>
  `${query}(max-width: ${breakpoints[breakpoint]}px)`

const up = (breakpoint: Breakpoint, query: string = '@media ') =>
  `${query}(min-width: ${breakpoints[breakpoint] + 1}px)`

export default {
  ...breakpoints,
  up,
  down,
}
